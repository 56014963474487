import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import _defineProperty from "/trader/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";
import { jsx as _jsx } from "@emotion/react/jsx-runtime";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import AppProviders from '../app-providers';
/**
 * Import global styles, global css or polyfills here
 * i.e.: import '@/assets/theme/style.scss'
 */

import 'tailwindcss/tailwind.css';
import 'styles/imports.css';
import 'styles/globals.css';
import 'styles/base.css';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify'; // Workaround for https://github.com/zeit/next.js/issues/8592

/**
 * @link https://nextjs.org/docs/advanced-features/custom-app
 */
var MyApp = function MyApp(_ref) {
  var Component = _ref.Component,
      pageProps = _ref.pageProps,
      err = _ref.err;
  return _jsxs(AppProviders, {
    children: [_jsx(Component, _objectSpread(_objectSpread({}, pageProps), {}, {
      err: err
    })), _jsx(ToastContainer, {
      position: "bottom-left",
      autoClose: 3000,
      hideProgressBar: false,
      newestOnTop: false,
      closeOnClick: true,
      rtl: false,
      pauseOnFocusLoss: true,
      draggable: true,
      pauseOnHover: true
    })]
  });
};

export default MyApp;