import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";

/**
 * Set your global app-providers (i.e: redux, react-query,...) here
 */
var AppProviders = function AppProviders(props) {
  return _jsx(_Fragment, {
    children: props.children
  });
};

export default AppProviders;